<detail-card>

    <div class="detail-card fix-card">
   
        <div class="card-title" >{ props.cardtitle }</div>

        <div class="details">
            <ul>
                <li each={ item in props.items }>{ item }</li>
            </ul>
        </div>

        <div class="card-detail-btn">
            <detail-card-popup html={this.props.html}></detail-card-popup>
        </div>

           
    </div>

    <div class="detail-card collaps-card">

        <button class="card-title card-title-btn" onclick={toggleDetails} >{ props.cardtitle }</button>

        <div if={state.showDetails} >
        <div class="details">
            <ul>
                <li each={ item in props.items }>{ item }</li>
            </ul>
        </div>

        <div class="card-detail-btn">
            <detail-card-popup html={this.props.html}></detail-card-popup>
        </div>

        </div>
           
    </div>

    <script>
        export default {
            state: {
                showDetails: false,
            },
            onMounted() {
                this.state.showDetails = false;
            },
            toggleDetails() {
                
                this.state.showDetails= !this.state.showDetails;
                
                if (this.state.showDetails) {
                    this.$('.collaps-card').style.height='clamp(224px, calc(30em - 5*10vw), 22em)';
                } else {
                    this.$('.collaps-card').style.height='clamp(58px, calc(9em - 16vw), 7em)';
                }
                
                this.update();

            }
            
        }

    </script>

    <style>

        .detail-card {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: min(1.5rem, 26px); 
            height: clamp(58px, calc(9em - 16vw), 7em);
            position: relative;
            background: #141b14;  
            background-image: radial-gradient(circle at 100% 100%, #141b14 0%, #080808 100%);         
        }

        .detail-card:before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: 90%;
            height: 90%;
            z-index: -1;
            background: #e3e3e3;
            transform: skew(1deg, 1deg);
        } 

        .detail-card:after {
            content: '';
            position: absolute;
            bottom: -2px;
            right: -2px;
            width: 90%;
            height: 90%;
            z-index: -1;
            background: #008f00;
            box-shadow: 0 0 10px #00ff00;
            transform: skew(1deg, 1deg);
        }  

        .collaps-card {
            height: clamp(58px, calc(9em - 16vw), 7em);
            display: none;
        }

        .fix-card {
            height: 224px;
            display: flex;
        }

        .card-title {
            background-color: transparent;
            border: none;
            display: block;
            color: #e3e3e3;
            text-align: left;
            padding: 12px;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif;           
        }

        .card-title-btn {
            cursor: pointer;
        }    

        .card-title-btn:hover {
            text-shadow: 1px 1px 2px #e3e3e3, 0 0 1em #e3e3e3, 0 0 0.2em #e3e3e3;
            color: #222;
        }

        .details li {
            text-decoration: none;
            font-size: 12px;
            padding: 4px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        }

        .details li:nth-child(1) {
            color: #c3c3c3;
        }

        .details li:nth-child(2) {
            color: #a3a3a3;
        }

        .details li:nth-child(3) {
            color: #939393;
        }

        .details li:nth-child(4) {
            color: #838383;
        }

        .details li:nth-child(5) {
            color: #737373;
        }

        .details ul {
            padding-left: 26px;
        }

        .details li::marker {
            content: "\27A3";
        }  

        .details li:hover::marker {
            color: #00ff00;
        } 

        @media screen and (max-width: 600px) {
            .fix-card {
                display: none;
            }
            .collaps-card {
                display: block;
            }
        }


    </style>


</detail-card>