<map-box>

    <div id="map"></div>

    <script>
        import {Map, View, Feature} from 'ol';
        import OSM  from 'ol/source/OSM';
        import {fromLonLat} from 'ol/proj'
        import Point from 'ol/geom/Point';
        import VectorSource from 'ol/source/Vector';
        import {Icon, Style} from 'ol/style';
        import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
        import {defaults as defaultInteractions} from 'ol/interaction';
        import Here from './here.svg';

        export default {
            onMounted() {
            
                const cntr = fromLonLat([8.226692,46.80121]);
                const homebase = fromLonLat([9.2270,47.32551]);

                const cntrMarker = new Feature({
                    type: 'icon',
                    geometry: new Point(homebase),
                });

                const map = new Map({
                    target: this.$('#map'),
                    layers: [
                        new TileLayer({
                        source: new OSM()
                        })
                    ],
                    view: new View({
                        center: cntr,
                        zoom:7,
                        minZoom: 2,
                        maxZoom: 10,
                    }),
                    interactions: defaultInteractions({
                        mouseWheelZoom: false
                    }),
                });

                const styles = {
                    'icon': new Style({
                        image: new Icon({
                            anchor: [5, 40],
                            anchorXUnits: "pixels",
                            anchorYUnits: "pixels",
                            src: Here,
                        }),
                    }),
                };

                const vectorLayer = new VectorLayer({
                    source: new VectorSource({
                        features: [cntrMarker],
                    }),
                    style: function (feature) {
                        return styles[feature.get('type')];
                    },
                });

                map.addLayer(vectorLayer);
            }
        }

    </script>

    <style>

        #map {
            width: 100%;
            height: 100%;
        }

        .ol-control button {
            background-color: #333;
            color: #c3c3c3;
        }

        .ol-control button:hover {
            color: #00ff00;
        }

        .ol-control button:focus {
            color: #00ff00;
        }

        .ol-attribution ul {
            background-color: #333;
            color: #c3c3c3;
            text-shadow: none;
        }

        .ol-attribution a {
            color: #c3c3c3;
            text-decoration: underline;
        }

        .ol-attribution a:hover {
            color: #00ff00;
        }

    </style>


</map-box>

