<service-item>

    <div class="service-box">

        <div class="halfbox hbo hbanimated" ></div> <div class="halfbox hbu hbanimated" ></div> 

        <div class="halfbox hbo hbl" ></div> <div class="halfbox hbu hbl" ></div> 

        <div class="title-box">

            <div class="service-title">            
                <div each={ item in getTitleItems() } >{ item }</div>
            </div>

        </div>

        <div class="halfbox hbo hbm" ></div> <div class="halfbox hbu hbm" ></div> 
 
        <div class="detail-box">

            <ul class="service-item-detail">
                
                <li each={ item in props.items }>{ item }</li>

            </ul>

        </div>
    </div>

    <script>

        export default {
            getTitleItems() {
                const ttlItems = this.props.servicetitle.split(" / ");
                if (ttlItems.length === 2) {
                    ttlItems[0] = ttlItems[0].concat(' /');
                }
                return ttlItems;
            },
            animateOnScroll() {
                const servicebox = this.$('.service-box')
                const vheight = window.visualViewport.height                
                let scrolly = window.scrollY;
                const otop = servicebox.offsetTop
                const oheight = servicebox.offsetHeight 
                let obottom = otop + oheight
                let scrollbottom = vheight + scrolly          
                if(obottom < scrollbottom) {
                    const hbsanim = this.$$('.hbanimated')
                    hbsanim.forEach(hitem => {
                        hitem.classList.add('visible') 
                    })

                    const titlebox = this.$('.title-box')
                    titlebox.classList.add('visible') 

                    if (window.visualViewport.width > 600) {
                        const detailbox = this.$('.detail-box')
                        detailbox.classList.add('visible')  
                    }
                } 
            },
            onMounted() {
                this.animateOnScroll() 
                window.addEventListener('scroll', this.animateOnScroll)
            }

        }

    </script>

    <style>

        .service-box {
            display: flex;
            width: 100%;
            height: 204px;
            position: relative;
            border-top: 2px solid #e3e3e3;  
            -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
            -moz-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
            -o-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);          
        }

        .title-box {
            left: -36%;
            width: 36%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;  
            background: #080808; 
            background-image: radial-gradient(circle at bottom, #141b14 0%, #080808 100%);     
        }

        .title-box.visible {
            left: 0;
            animation-name: move-visible-title;
            animation-duration: 360ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;  
            animation-delay: 0; 
        }

        .halfbox {
            position: absolute;
            width: 46px;
            height: 50%;
        }

        .hbo {
            top: 0px;
            background: #001f00;            
            background-image: repeating-linear-gradient(#00ff00, #000f00 1px, #080808 1px, #080808 6px); 
            transform: skewX(25deg);
        }

        .hbu {
            top: 50%;
            background: #e3e3e3;
            background-image: repeating-linear-gradient(#e3e3e3, #a3a3a3 1px, #080808 1px, #080808 6px); 
            transform: skewX(-25deg);
        }

        .hbl {
           left: -23px;
           z-index: 2;
        }

        .hbm {
            left: calc(36% - 23px);
            z-index: -1;
            display: block; 
        }

        .hbanimated {
            left: -23px;
            z-index: 1;
        }

        .service-title {
            background-color: transparent;
            border: none;
            display: block;
            color: #e3e3e3;
            text-align: left;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif; 
        }

        .service-title div:nth-child(1) {
            padding-top: 20%;
            padding-left: 25%;
        }

        .service-title div:nth-child(2) {
            padding-top: 1em;
            text-align: right;
            padding-right: 25%;
        }

        .detail-box {
            position: absolute;         
            top: 0px;  
            left: -100%;  
            height: 100%;
            width: 64%;
            overflow: hidden;
            background: #080808;
            background-image: radial-gradient(circle at top, #141b14 0%, #080808 100%);
            z-index: -2;
            display: flex;
            align-items: center;  
        }

        .detail-box.visible {
            left: 36%;  
            animation-name: move-visible-detail;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;  
            animation-delay: 0; 
        }

        .service-item-detail {
            padding-left: 0;
        }

        .service-item-detail li {
            color: #a3a3a3;
            text-decoration: none;
            font-size: 0.8em;
            padding-right: 26px;
            padding-top: 0.8em;
            padding-bottom: 0.8em;
            padding-left: 52px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        }

        .service-item-detail li::marker {
            content: "";
        }  

        .service-item-detail li:nth-child(2) {
            padding-left: 78px;
        }

        .service-item-detail li:nth-child(3) {
            padding-left: 78px;
        }
 
        .hbanimated.visible {
            animation-name: move-visible;
            animation-duration: 1005ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;  
            animation-delay: 0s;        
        }

        @keyframes move-visible {
            0% {
                left: -23px;
            }
            36% {
                left: calc(36% - 23px);
            }
            100% {
                left: 100%;
            }
        }

        @keyframes move-visible-600 {
            0% {
                left: -23px;
            }
            100% {
                left: 100%;
            }
        }

        @keyframes move-visible-title {
            0% {
                left: -36%;
            }
            100% {
                left: 0;
            }
        }

        @keyframes move-visible-title-600 {
            0% {
                left: -100%;
            }
            100% {
                left: 0;
            }
        }

        @keyframes move-visible-detail {
            0% {
                left: -100%;
            }
            36% {
                left: calc(-28% + 23px);
            }
            100% {
                left: 36%;
            }
        }

        @media screen and (max-width: 600px) {
            .service-box {
                display: flex;
                width: 100%;
                height: 204px;
                position: relative;
                clip-path: border-box;
            }

            .title-box {
                left: -100%;
                width: 100%;      
            }

            .hbm {
                display: none;
            }

            .detail-box {     
                left: 0px;  
                height: 0%;
                width: 100%;
                z-index: 1;
                transition: height 1s ease-in-out;
            }

            .service-box:hover .detail-box {
                top: 0px;
                height: 100%;
                transition: height 1s ease-out;
            }

            .service-title div:nth-child(2) {
                padding-top: 1em;
                text-align: left;
                padding-left: 25%;
            }

            .title-box.visible {
                animation-name: move-visible-title-600;
            }

            .hbanimated.visible {
                animation-name: move-visible-600;
                animation-duration: 720ms;      
            }

        }

        @media screen and (max-width: 360px) {
            .service-item-detail li {
                font-size: 0.7em;
                padding-right: 0.8em;
                padding-top: 0.4em;
                padding-bottom: 0.4em;
                padding-left: 0.8em;
            }

            .detail-box {     
                z-index: 3;
            }

            
            .service-item-detail li:nth-child(2) {
                padding-left: 0.8em;
            }

            .service-item-detail li:nth-child(3) {
                padding-left: 0.8em;
            }
            */
        }


    </style>


</service-item>