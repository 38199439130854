<home id="home">
    
    <article class="home-container">

        <div class="home-txt">
            <p>Annaliese Fässler</p>
            <p>Software Engineer</p>
        </div>
        
    </article>

    <style>

        .home-container {
            display: grid;
            padding: 26px;
            min-height: 20em;
            justify-content: center;
            align-content: center;       
        }

        .home-txt {
            color: #00ff00;
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
            font-size:  20px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace; 
            width: 17ch;
        }

        .home-txt p {            
            white-space: nowrap;
            overflow: hidden; 
            width: 17ch;         
        }


    </style>
    
</home>