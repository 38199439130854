<navbar>

    <div class="navbar-background">
        <div class="navbar">
            <a href="#home">af</a> 

            <a href="#contact">CONTACT</a> 
            <a href="#services">SERVICES</a> 
            <a href="#work">WORK</a>   
            <a href="#about">ABOUT</a>

        </div>
    </div>

    
    <button if={!state.showSidebar} class="sidebaricon" onclick={toggleSidebar} >
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
    </button>
    

    <div if={state.showSidebar} class="sidebar" >
        <a href="javascript:void(0)" onclick={toggleSidebar}>    
            <div class="close"></div> 
        </a>
        <a href="#home" onclick={toggleSidebar}>HOME</a> 
        <a href="#about" onclick={toggleSidebar}>ABOUT</a>
        <a href="#work" onclick={toggleSidebar}>WORK</a> 
        <a href="#services" onclick={toggleSidebar}>SERVICES</a>           
        <a href="#contact" onclick={toggleSidebar}>CONTACT</a> 
    </div>

    <style>

        .navbar-background {
            background-color: #080a08;
            top: 12;
            position: fixed;   
            display: block;
            width: 100%;
            z-index: 1000;
        }

        .navbar {
            overflow: hidden;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: clamp(600px, 100%, 1600px);
        }

        .navbar a:first-child {
            float: left;
            color: #0a0a0a;
            padding: 4px 26px;
            font-size: 30px;
            font-weight: 1000;
            text-shadow: 1px 1px 2px #00ff00, 0 0 1em #00ff00, 0 0 0.2em #00ff00;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        }

        .navbar a:first-child:hover {
            padding: 2px 26px;
            font-size: 34px;
        }

        .navbar a {
            float: right;
            display: block;
            color: #e3e3e3;
            text-align: center;
            padding: 12px 26px;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif;
        }        

        .sidebar {
            overflow: hidden;
            background-color: #080a08;
            width: 100%;
            display: none;  
            position: fixed; 
        }

        .sidebar a {
            display: block;
            color: #e3e3e3;
            text-align: left;
            padding: 12px 26px;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif;
        }

        .sidebaricon {
            float: left;
            background-color: #080a08; 
            border-radius: 50%;
            top: 12px;
            position: fixed;
            border: none;
            padding: 12px 12px;
            margin-left: 14px;
            cursor: pointer;
            display: none;
        }

        a:hover {
            text-shadow: 1px 1px 2px #00ff00, 0 0 1em #00ff00, 0 0 0.2em #00ff00;
            color: #222;
        }

        .bar {
            height: 2px;
            background-color: #e3e3e3;
        }

        .sidebaricon:hover > div {
            height: 2px;
            background-color: #222;
            box-shadow: 1px 1px 2px #00ff00, 0 0 1em #00ff00, 0 0 0.2em #00ff00;
        }

        .bar1 {
            width: 24px;
            margin: 4px 0;
        }

        .bar2 {
            width: 14px;
            margin: 4px 0px 4px 10px;
        }

        .bar3 {
            width: 20px;
            margin: 4px 0px;
        }

        .close:after {
            content: "\d7";
            font-size: 34px;
        }


        @media screen and (max-width: 600px) {
            .navbar {
                display: none;
            }
            .sidebaricon {
                display: block;
                z-index: 1000;
            }
            .sidebar {
                display: block;
                z-index: 1000;
            }
        }
 
    </style>

    <script>
    
        export default {
            state: {
                showSidebar: false
            },
            toggleSidebar() {
                this.update({
                    showSidebar: !this.state.showSidebar
                })
            }
        }
    </script>

</navbar>
