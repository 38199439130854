<contact id="contact">

    <div class="contact-container">

        <div class="section-title">CONTACT</div>

        <div class="section-subtitle">I am based in ...</div>

        <div class="map-box">
            <map-box/>
        </div>

         <div class="collaborate-box">

            <div class="section-subtitle">Send Me a Message</div>

            <a href="mailto:info@annaliesefassler.ch" title="Email me at info at annaliesefassler dot ch" target="_blank" class="mailto">

                <span class="mailclose">              
                    <svg width="124px" height="84px" xmlns="http://www.w3.org/2000/svg" >
                        <polyline points="2,2 120,2 120,80 2,80 2,4 59,50 120,4" />
                        Email Me
                    </svg>
                </span>
                <span class="mailopen">
                    <svg width="124px" height="134px" xmlns="http://www.w3.org/2000/svg" >
                        <polyline points="2,50, 61,2 120,50 120,130 2,130 2,51 61,100 120,50" />
                        <polyline points="8,30, 114,30 114,54 61,100 8,55 8,30" />
                        <line x1="18" y1="44" x2="32" y2="44" />
                        <line x1="18" y1="50" x2="82" y2="50" />
                        <line x1="18" y1="56" x2="68" y2="56" />
                        Email Me
                    </svg>
                </span>
            </a>
        
        </div>    

    </div>


    <style>

        .contact-container {          
            width: clamp(300px, 100%, 1600px);
            left: 0;
            right: 0;
            margin: 0 auto;     
        }

        .section-title {
            display: block;
            color: #e3e3e3;
            text-align: center;
            padding: 52px 26px 26px 26px;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif;
        }

        .section-subtitle {
            display: block;
            color: #00ff00;
            text-align: center;
            padding: 52px 26px 12px 26px;
            text-decoration: none;
            font-size: 18px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        }

        .map-box {
            margin: 26px;
            height: 332px; 
            overflow: hidden;           
        }

        .collaborate-box {
            margin: 26px;   
            overflow: hidden;  
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;  
            position: relative;  
            height: 25rem; 
        }

        .collaborate-box > .section-subtitle {
            padding: 0px 12px 52px 12px;
        }

        .collaborate-box:after {
            margin: 26px;
            content: "Get In Touch";
            white-space: pre;
            position: absolute;
            transform: scale(1, 3);
            -webkit-transform: scale(1, 3); 
            color: #080f08;
            z-index: -1;
            width: 100%;
            font-size: 10vw;
            font-family: Sans-Serif; 
            text-align: center;
        }

        .mailto {
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center; 
            cursor: pointer;
            fill: none;  
            fill-rule: nonzero;
            stroke-width: 2;
        }


        .mailclose {
            height: 82px;
            width: 122px;
            stroke: #e3e3e3;
            display: block;
            margin-top: 52px;
            background-color: #333333;
        }

        .mailopen {
            height: 134px;
            width: 124px;
            stroke: #00ff00;
            fill: #333333;
            display: none;
        }

        .mailto:hover .mailopen {
            display: block;
            stroke: #00ff00;
        }

        .mailto:hover .mailclose {
            display: none;
        }



    </style>


</contact>