<my-component>
  
    <navbar></navbar>  
    <home></home>
    <about></about>
    <work></work>
    <services></services>
    <contact></contact>
  
</my-component>
