<about id="about">

  <article class="about-container">

    <div class="section-title">ABOUT</div>

    <div class="section-subtitle">My Intro</div>
    <p class="section-paragraph">
      I'm a software engineer with several years background in creating complex enterprise applications. 
      My expertise is in backend development using Java, JEE, Spring Framework and databases. In my various steps into frontend development 
      I used common web technologies like HTML, CSS and JavaScript. <br>
      I am experienced in all aspects of Software Development Life Cycle. I analyzed and documented requirements, designed and implemented software modules, 
      defined and realized the integration and deployment (CI/CD) process, maintained and refactored applications and I planed and accomplished data and software migrations.   
    </p>
    <p class="section-paragraph">
      I enjoy creative problem solving and I'm passionate about building clean, smart and highly scalable software. As a pragmatic and result oriented engineer 
      my focus is on finding the best and most appropriate solution and on delivering high quality software. 
    </p>

    <div class="section-subtitle">My Expertise. My Experience</div>  

    <div class="skill-container" >

      <div class="skill-sidebox">
        <p class="section-subtitle tts-box">Technologies</p>
        <p class="section-subtitle tts-box">Tools</p>
        <p class="section-subtitle tts-box">Standards</p>

      </div>

      <div>

        <skill-box each={ item in skills } { ...item } ></skill-box>

      </div>
    </div>

    <div class="education-box">
      <div class="section-subtitle">Education</div>

      <p class="txt-centered">Certificate of Advanced Studies in Business Analysis <br> 
      HWZ University of Applied Sciences in Business Administration Zurich</p>

      <p class="txt-centered">Master of Science in Computer Science<br>University of Fribourg</p>

      <p class="txt-centered">Bachelor of Science in Computer Science<br>University of Fribourg</p>

    </div>
  
  </article>

  <script>

    export default {
      skills: [ { skilltitle: "Back End",
                  items: ["Java", 
                            "JEE (EJB, JPA, JAX-RS, JAX-WS)", 
                            "Spring Framework, Spring Boot",
                            "REST, SOAP",
                            "OpenAPI / Swagger",
                            "OAuth2 (JWT, JWK)",
                            "Maven Build Management",
                            "Weblogic Application Server",
                            "Tomcat Web Application Server",
                            "Node.js"
                          ] },
                { skilltitle:"Database",
                  items: ["SQL",
                          "PL/SQL",
                          "Oracle",
                          "DB2",
                          "PostpreSQL, PostGIS"
                          ] },           
                { skilltitle: "Front End",
                  items: ["HTML5",
                          "CSS3",
                          "JavaScript",
                          "webpack.js",
                          "Cypress",
                          "Puppeteer",
                          "JSF, Primefaces"
                          ] },
                { skilltitle: "DevOps",
                  items: ["Jenkins Pipeline and Job DSL",
                          "Groovy",
                            "Git, GitHub",
                            "Openshift",
                            "Cloud Foundry" 
                          ] },
                { skilltitle: "Business Analysis",
                  items: ["UML",
                          "BPMN",
                          "Confluence",
                          "Jira"
                          ] },
                { skilltitle: "Business",
                  items: ["Insurance",
                          "Intralogistics",
                          "Public Administration",
                          "Finance"
                          ] }
          ],
      
      animateOnScroll() {
          const ttboxes = this.$$('.tts-box:not(.visible)')
          const vheight = window.visualViewport.height
          let scrolly = window.scrollY;
          ttboxes.forEach(ttitem => {
            const otop = ttitem.offsetTop
            const oheight = ttitem.offsetHeight         
            let obottom = otop + oheight
            let scrollbottom = vheight + scrolly          
            if(obottom < scrollbottom) {
                ttitem.classList.add('visible')                
            } 
          })  
        },
        onMounted() {
          this.animateOnScroll() 
          window.addEventListener('scroll', this.animateOnScroll)
        }
        
    }

  </script>

 

  <style>

    :root {
          --translate-x: -30vw;
    }

    .about-container {         
        min-height: 20em;
        width: clamp(300px, 100%, 1600px);
        left: 0;
        right: 0;
        margin: 0 auto; 
        padding-bottom: 52px;     
    }  

    .section-title {
        display: block;
        color: #e3e3e3;
        text-align: center;
        padding: 52px 26px 26px 26px;
        text-decoration: none;
        font-size: 16px;
        font-family: Sans-Serif;
    }

    .section-subtitle {
        display: block;
        color: #00ff00;
        text-align: center;
        padding: 52px 26px 26px 26px;
        text-decoration: none;
        font-size: 18px;
        font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    }

    p {
        color: #e3e3e3;;
        text-decoration: none;
        font-size: 16px;
        font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;

    }

    .section-paragraph {
        display: block;
        text-align: justify;
        padding: 12px 26px;
    }

    .txt-centered {
        display: block;
        text-align: center;
        padding: 12px 26px;
    }

    .skill-container {
      display: grid;
      grid-template-columns: 30% 70%;
      padding-top: 26px; 
    }

    .skill-sidebox {
        overflow: hidden;  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding: 26px;
    }

    .tts-box {
      border: 1px solid #00ff00;      
      border-radius: 0px 26px;
      transition: border-radius 1s;       
      padding: 26px;
      margin-bottom: 52px;
      margin-top: 26px;
      transform: translateX(-100vw) skewX(-20deg);
    }

    .tts-box.visible {
      animation-name: tts-move-visible;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-delay: 0s;
      transform: translateX(0) skewX(0);
    }

    .tts-box:hover {
      border-radius: 26px 0;
    }

    @keyframes tts-move-visible {
      0% {
        transform: translateX(-100vw) skewX(-20deg);
        border-radius: 26px 0;
      }
      60% {
        transform: translateX(0) skewX(-20deg);
        border-radius: 26px 0;
      }
      100% {
        transform: skewX(0deg);
        border-radius: 0px 26px;
      }
    }

    @media screen and (max-width: 600px) {
        .skill-container {
            grid-template-columns: 100%;
            padding: 0; 
        }
        .skill-sidebox {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 26px 26px;
        }
        .tts-box {
            font-size: 16px;
            padding: 12px;
            margin: 8px;
        }
    }

    @media screen and (600px <= width <= 820px) {
        .tts-box {
          font-size: 16px;
          padding: 12px;
        }
    }
 
   </style>

</about>