<skill-box>

    <div class="skill-box">

          <div class="skill-title" >{ props.skilltitle }</div>

          <div class="skill-details">
              
            <div class="skill-button" each={ item in props.items }>{ item }</div>
              
          </div>
            
    </div>


    <script>
      export default {
        activateOnScroll() {
          const skb = this.$('.skill-box')
          const otop = skb.offsetTop
          const oheight = skb.offsetHeight
          const vheight = window.visualViewport.height
          let scrolly = window.scrollY;
          let obottom = otop + oheight
          let scrollbottom = vheight + scrolly                   
          if(obottom < scrollbottom) {
            const skItems = this.$$('.skill-box .skill-button')
            skItems.forEach(item => {   
              item.classList.add('visible')                
            })
          }           
        },
        onMounted() {
          this.activateOnScroll() 
          window.addEventListener('scroll', this.activateOnScroll)
        }
      }

    </script>


    <style>

    .skill-box {
        display: grid;
        grid-template-columns: 25% 75%;
        padding: 26px;
    }

      .skill-title {
        display: inline-block;
        text-align: right;
        color: #e3e3e3;
        padding-right: 26px;
        text-transform: uppercase;      
        overflow: hidden;
        font-size: 16px;
        font-family: Sans-Serif;  
      }

      .skill-details {
          display: flex;
          padding-left: 26px;
          flex-flow: row wrap;
      }

      .skill-button {
          position: relative;
          display: inline-block;
          color: #e3e3e3;
          font-size: 12px;
          margin: 0px 12px 18px 0px;
          padding: 10px;
          font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;         
          box-shadow: 1px 1px #00ff00,
                      1px 1px #080808 inset,
                      2px 2px #a3a3a3 inset;   
          transform: scale(0);  
          transition: transform 1s;        
      }

      .skill-button.visible {
        transform: scale(1);
      }

      
      .skill-button:hover {
          animation-name: animateborder;
          animation-duration: 2s;
          animation-iteration-count: infinite;
      }
      

    @keyframes animateborder {
        0%{ 
          box-shadow: 1px 0px #a3a3a3,
                      1px 1px #00ff00,
                      1px 1px #a3a3a3 inset;
           }
        25%{
          box-shadow: 0px 1px #00ff00,
                      1px 0px #00ff00 inset,
                      1px 0px #a3a3a3,
                      0px 1px #a3a3a3 inset;
           }
        50%{
          box-shadow: 1px 1px #00ff00 inset,
                      1px 1px #a3a3a3;
          }
        75%{
          box-shadow: 1px 0px #00ff00,
                      0px 1px #00ff00 inset,
                      1px 0px #a3a3a3 inset,
                      0px 1px #a3a3a3;
            }
        100%{
          box-shadow: 1px 1px #00ff00,
                      1px 1px #a3a3a3 inset;  
            }
    }
      
    @media screen and (max-width: 600px) {
      .skill-box {
        grid-template-columns: auto;
      }
      .skill-title {
        text-align: center;
        padding: 26px 26px;
      }
      .skill-details {
        justify-content: center;
        padding: 0px 26px;
      }
    }

    </style>

</skill-box>