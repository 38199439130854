<services id="services">

<div class="service-container">

    <div class="section-title">SERVICES</div>

    <div class="section-subtitle">What I Offer</div>

    <div class="service-item-container" >
        <service-item each={item in servicedetails} {...item} ></service-item>
    </div>

  </div>

    <script>

        export default {                         
            servicedetails: [ {  
                servicetitle: "Development / Programming",
                items: ["Designing and implementing modules, microservices, applications",
                        "Developing web solutions back end and front end",
                        "Extending or enhancing applications",
                        "Fixing bugs or other annoying behaviour"
                       ]
                },
                {  
                servicetitle: "API Development / Integration",
                items: ["Designing, specifying and documenting REST or SOAP APIs",
                        "Implementing REST, SOAP services and clients",
                        "Implementing Gateways, Proxies",
                        "Securing APIs"
                       ]
                },
                {  
                servicetitle: "Software / Data Migration",
                items: ["Analysing the data set and cleaning up obsolete or erroneous data",
                        "Writing migration scripts and programs",
                        "Modernizing and refactoring lecacy systems or parts of it",
                        "Upgrading or replacing software or libraries"
                         ] 
                },
                {
                servicetitle:"Testing",
                items: ["Implementing Unit and Integration tests", 
                        "Testing APIs or implementing autometed API tests",
                        "UI or End-to-End Testing",
                        "Creating autometed UI or E2E tests"                        
                         ] 
                }, 
                { 
                servicetitle: "DevOps",
                items: ["Defining and designing the integration and deployment workflow", 
                        "Implementing the Integration and Deployment Pipeline",
                        "Refactoring or migrating the build process",
                        "Modernizing or refactoring the CI/CD Pipeline"
                         ] 
                }
                
            ],            
        }

    </script>


    <style>
        
        .service-container {           
            min-height: 20em;
            width: clamp(300px, 100%, 1600px);
            left: 0;
            right: 0;
            margin: 0 auto;   
            background: transparent;
        }

        .section-title {
            display: block;
            color: #e3e3e3;
            text-align: center;
            padding: 52px 26px 26px 26px;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif;
        }

        .section-subtitle {
            display: block;
            color: #00ff00;
            text-align: center;
            padding: 52px 26px 52px 26px;
            text-decoration: none;
            font-size: 18px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        }

        .service-item-container {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            row-gap: 52px;
        }

        service-item {
            display: flex;
            width: clamp(296px, 100%, 684px);
            margin: 26px;
        }


    </style>

</services>