<detail-card-popup>

    <div class="{'popup'.concat(state.activated ? ' active' : '')}" >

        <div class="popup-content" >           
        </div>

       
        <div class="close" onclick={toggleActive} ></div>
        

    </div>

    
    <button class="popup-btn" onclick={togglePopup}><span></span><span></span><span></span></button>

    <script>


        export default {
                        
            state: {
                activated: false
            },
            setInnerHTML() {
                this.$('.popup-content').innerHTML = this.props.html
            },
            onMounted() {
                this.setInnerHTML()
            },                           
            toggleActive(){
                this.update({
                    activated: false
                })
            },
            togglePopup(){
                this.update({
                    activated: true                    
                })
            }
                           
        }


    </script>

    <style>

        .popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            opacity: 0;
            width: 98%;
            max-width: 1600px;
            max-height: calc(99% - 48px);
            border-radius: 20px;
            background: rgba(10, 10, 10, 0.90);
            background-image:   linear-gradient(45deg,
                                                transparent 0% 50%,
                                                rgba(0, 128, 0, 0.2) 50% 80%,
                                                rgba(0, 64, 0, 0.2) 80% 87%,
                                                rgba(0, 32, 0, 0.2) 87% 94%,
                                                rgba(0, 8, 0, 0.2) 94% 100%
                                            ),
                                linear-gradient(
                                                315deg,
                                                transparent 0% 50%,
                                                rgba(0, 128, 0, 0.2) 50% 80%,
                                                rgba(0, 64, 0, 0.2) 80% 87%,
                                                rgba(0, 32, 0, 0.2) 87% 94%,
                                                rgba(0, 8, 0, 0.2) 94% 100%
                                            ),
                                linear-gradient(
                                                135deg,
                                                transparent 0% 50%,
                                                rgba(0, 128, 0, 0.2) 50% 80%,
                                                rgba(0, 64, 0, 0.2) 80% 87%,
                                                rgba(0, 32, 0, 0.2) 87% 94%,
                                                rgba(0, 8, 0, 0.2) 94% 100%
                                            ),
                                linear-gradient(
                                                225deg,
                                                transparent 0% 50%,
                                                rgba(0, 128, 0, 0.2) 50% 80%,
                                                rgba(0, 64, 0, 0.2) 80% 87%,
                                                rgba(0, 32, 0, 0.2) 87% 94%,
                                                rgba(0, 8, 0, 0.2) 94% 100%
                                            );
            z-index: 5;
            transition: 1s;         
            overflow-y: scroll;
            overflow-x: hidden;
            display: flex;
        }

        .popup.active {
            top: 48px;
            transform: translateX(-50%) scale(1);
            opacity: 1;
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        .popup::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .popup {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }


        .close {
            position: sticky;
            top: 14px;
            right: 14px; 
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 50%;
            background: #080808;
            cursor: pointer;
            color: #e3e3e3;
            border: none;
            margin-left: calc(10% - 54px);
        }

        .close:hover {
            text-shadow: 1px 1px 2px #00ff00, 0 0 1em #00ff00, 0 0 0.2em #00ff00;
            color: #222;
        }

        .close:after {
            content: "\d7";
            font-size: 34px;            
        }

        .popup-content {
            display: flex;
            flex-direction: column;
            width: 80%;
            height: 100%;
            color: #e3e3e3;
            text-decoration: none;
            font-size: 16px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
            background: #000900;
            padding: 26px;
            margin-left: 10%;
        }

        .card-title {
            padding: 26px 0px 26px 0px;         
        }

        .emphasis {
            color: #00ff00;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        ul li ul {
           list-style-type: outside; 
           margin: 12px;
           padding: 12px 12px 12px 10%;
        }

        ul li ul li::marker {
            content: "\27A3\0020";
            color: #00ff00;
            padding-right: 26px;
            font-size: 14px;
        }

        .popup-content p {
            padding-bottom: 26px;
        }

        .popup-btn {
            background-color: transparent;
            border: none;
            width: 80px;
            text-align: right; 
            position: absolute;         
            bottom: 12px;  
            right: 20px;        
        }
       
       
        .popup-btn span::after {
            content: "\276F";
            font-size: 16px;
            color: #737373;
            letter-spacing: -3px;
            font-weight: 100;   
            animation-name: animated-marker;
            animation-duration: 3s;
            animation-timing-function: steps(3, jump-none);
            animation-iteration-count: infinite;
            transform: translateX(0) skewX(0);
        }       
        
               
        .popup-btn span:nth-child(1)::after {            
            animation-delay: 0s;        
        }

        .popup-btn span:nth-child(2)::after {            
            animation-delay: 1s;       
        }

        .popup-btn span:nth-child(3)::after {            
            animation-delay: 2s;        
        }

        .popup-btn:hover span::after {
            text-shadow: 1px 1px 2px #00ff00, 0 0 1em #00ff00, 0 0 0.2em #00ff00;
            color: #222;  
            animation-name: none;     
        }

        

        @keyframes animated-marker {
            0% {
                color: #e3e3e3;         
            }
            50% {
                color: #a3a3a3;       
            }
            100% {
                color: #636363;        
            }
        }

        @media screen and (max-width: 600px) {
            .popup {
                max-height: 96%;
            }
            .popup.active {
                top: 2%;
            }
            .popup-content {
                width: 90%;
                margin-left: 5%;
            }
            
        }

    </style>

</detail-card-popup>