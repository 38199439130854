<work id="work">

    <div class="work-container">

        <div class="section-title">WORK</div>


        <div class="section-subtitle">Recent Projects and Work Experience</div>


        <div class="card-container">
            
            <detail-card each={item in workdetails} {...item} ></detail-card>

        </div>
      
  </div>

     <script>

        import worksuva from './includes/work-detail-suva.html'
        import workallianz from './includes/work-detail-allianz.html'
        import workaxa from './includes/work-detail-axa.html'
        import workbit from './includes/work-detail-bit.html'
        import workmore from './includes/work-detail-more.html'

        export default {                           
           workdetails: [ {  
                cardtitle: "Migrating from JEE to S/4HANA and implementing an API Gateway",
                items: ["Reverse engineering and collecting requirements",
                         "Evaluating a GIS solution",
                         "Defining the system's integration architecture",
                         "Implementing an API Gateway and RESTful interfaces"
                       ],
                 html: workaxa
                },
                {
                cardtitle: "Implementing a new Online Customer Portal",
                items: ["Developing RESTful clients and services",
                        "Implementing multithreading and reduce performance bottlenecks",
                        "Securing the application with OAuth2",
                        "Creating API load tests"
                       ],
                 html: workallianz
                },  
                {       
                cardtitle: "Upgrading, Modernizing and Refactoring a Software Suite",
                items: ["Migrating the build process and dependency management",
                        "Upgrading Java and WebLogic server versions",
                        "Modernizing the persistence layer",
                        "Migrating the SOAP web services",
                        "Reorganizing the Java batch job packaging"
                    ],
                html: worksuva
                },
                {
                cardtitle: "Implementing a Document and Information Management System",
                items: ["Designing software modules and the database schema",
                        "Implementing backend services",
                        "Creating UIs",
                        "Planning, implementing and accomplishing the data migration"
                       ],
                 html: workbit
                },
                {
                cardtitle: "Working for an intralogistics system supplier",
                items: ["Developing a new automated material flow management system",
                        "Extending and maintaining the legacy material flow system",
                        "Collaborate with project teams in integrating the material flow software in warehouse centers"
                       ],
                 html: workmore
                }
            ]
        }

     </script>   

    <style>
        
        .work-container {          
            min-height: 20em;
            width: clamp(300px, 100%, 1600px);
            left: 0;
            right: 0;
            margin: 0 auto;      
        }

        .section-title {
            display: block;
            color: #e3e3e3;
            text-align: center;
            padding: 52px 26px 26px 26px;
            text-decoration: none;
            font-size: 16px;
            font-family: Sans-Serif;
        }

        .section-subtitle {
            display: block;
            color: #00ff00;
            text-align: center;
            padding: 52px 26px 26px 26px;
            text-decoration: none;
            font-size: 18px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        }

        .section-paragraph {
            color: #e3e3e3;
            text-decoration: none;
            font-size: 16px;
            font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
            display: block;
            text-align: justify;
            padding: 12px 26px;
        }

        .card-container {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        detail-card {
            display: flex;
            width: clamp(298px, 100%, 424px);
            margin: 26px;
            padding: 26px min(1.5vw, 26px);
        }

      
    </style>


</work>